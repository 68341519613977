import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../css/footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faShieldAlt, faTowerCell, faFan } from '@fortawesome/free-solid-svg-icons';
import { faCreativeCommonsSamplingPlus } from '@fortawesome/free-brands-svg-icons'; // Import the icon

const Footer = ({ ws }) => {
  const [status, setStatus] = useState('');
  const [dc, setDc] = useState('');
  const [isVpn, setIsVpn] = useState(false);
  const [latency, setLatency] = useState(null);
  const [isConnected, setIsConnected] = useState(true);
  const [gpuInfo, setGpuInfo] = useState({ total: 0, free: 0, used: 0 });
  const [fanSpeed, setFanSpeed] = useState(0);

  useEffect(() => {
    let pongTimeout;
    const handleWebSocketMessage = (message) => {
      if (message.status && message.time) {
        setStatus(message.status);
      } else if (message.dc) {
        setDc(message.dc);
      } else if (message.is_vpn) {
        setIsVpn(message.is_vpn);
      } else if (message.command === 'GPU') {
        setGpuInfo({
          total: message.total,
          free: message.free,
          used: message.used
        });
        const usagePercentage = (message.used / message.total) * 100;
        setFanSpeed(usagePercentage); // Adjust fan speed based on GPU usage
      } else if (message.message === 'pong') {
        clearTimeout(pongTimeout);
        const ctime = message.time;
        const now = Date.now();
        setLatency(`${now - ctime} ms`);
        setIsConnected(true);
      }
    };

    ws.on('message', handleWebSocketMessage);

    const interval = setInterval(() => {
      const timestamp = Date.now();
      ws.send({ command: 'ping', params: { time: timestamp } });
      pongTimeout = setTimeout(() => {
        setIsConnected(false);
        setLatency('Offline');
      }, 5000);
    }, 4000);

    return () => {
      clearInterval(interval);
      ws.off('message', handleWebSocketMessage);
    };
  }, [ws]);

  const disableCopy = (event) => {
    event.preventDefault();
  };

  const disableContextMenu = (event) => {
    event.preventDefault();
  };

  const renderStatusIcon = () => {
    const commonStyles = { display: 'inline-block', marginRight: '8px' };

    switch (status) {
      case 'red':
        return <span className="blink icon-spacing" style={{ ...commonStyles, color: 'red' }}>⬤</span>;
      case 'caution':
        return <span className="blink icon-spacing" style={{ ...commonStyles, color: 'orange' }}>⬤</span>;
      case 'green':
        return <span className="blink icon-spacing" style={{ ...commonStyles, color: 'green' }}>⬤</span>;
      default:
        return <span className="blink icon-spacing" style={{ ...commonStyles, color: 'red' }}>⬤</span>;
    }
  };

  const renderRegion = () => {
    return <span>{dc}</span>;
  };

  const renderIcon = () => {
    if (isVpn) {
      return (
        <>
          <FontAwesomeIcon icon={faShieldAlt} style={{ marginRight: '5px' }} />
          <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: '5px' }} /> {renderRegion()}
        </>
      );
    } else {
      return (
        <>
          <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: '5px' }} /> {renderRegion()}
        </>
      );
    }
  };

  const interpolateColor = (color1, color2, factor) => {
    const result = color1.slice();
    for (let i = 0; i < 3; i++) {
      result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
    }
    return `rgb(${result.join(",")})`;
  };

  // Get dynamic fan color based on usage percentage
  const getFanColor = () => {
    const green = [0, 255, 0];
    const orange = [255, 165, 0];
    const red = [255, 0, 0];

    if (fanSpeed < 50) {
      return interpolateColor(green, orange, fanSpeed / 50);
    } else {
      return interpolateColor(orange, red, (fanSpeed - 50) / 50);
    }
  };

  const renderGpuInfo = () => (
    <div className="gpu-info no-copy" onCopy={disableCopy} onContextMenu={disableContextMenu}>
      GPU's <div className="fan-icon" style={{ animationDuration: `${100 / 130}s`, color: getFanColor() }}>
        <FontAwesomeIcon icon={faFan} />
      </div>
      <div className="tooltip">Powered by Hugging Face ZeroGPU. Allocations may vary dynamically.</div>
      <div className="gpu-details">
        <div>Total: {gpuInfo.total} <span className='blink'><FontAwesomeIcon icon={faCreativeCommonsSamplingPlus} style={{ marginLeft: '5px', color: "green" }} /></span></div>
        <div>Used: {gpuInfo.used} <span className='blink'><FontAwesomeIcon icon={faCreativeCommonsSamplingPlus} style={{ marginLeft: '5px', color: "green" }} /></span></div>
        <div>Free: {gpuInfo.free} <span className='blink'><FontAwesomeIcon icon={faCreativeCommonsSamplingPlus} style={{ marginLeft: '5px', color: "green" }} /></span></div>
      </div>
    </div>
  );

  return (
    <footer className="footer">
      <div className="links">
        <ul>
          <li><Link to="/privacy-policy" className="link">Privacy Policy</Link></li>
          <li><Link to="/terms-of-service" className="link">Terms of Service</Link></li>
        </ul>
      </div>
      {renderGpuInfo()}
      <div className="status no-copy"
        onCopy={disableCopy}
        onContextMenu={disableContextMenu}
      >
        <div className="status-line">
          System Status {renderStatusIcon()}
        </div>
        <div className="status-line connected-region">
          {renderIcon()}
        </div>
        {latency !== null && (
          <div className="status-line connected-region">
            <FontAwesomeIcon
              icon={faTowerCell}
              className='fa-fade'
              style={{ marginRight: '5px', color: isConnected ? 'green' : 'red' }}
            /> {latency}
          </div>
        )}
      </div>
    </footer>
  );
};

export default Footer;