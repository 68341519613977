import React from 'react';

const NoRightClickImage = ({ src, alt, className }) => {
  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <img src={src} alt={alt} className={className} style={{ display: 'block' }} onContextMenu={handleContextMenu} />
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'transparent',
        }}
        onContextMenu={handleContextMenu}
      />
    </div>
  );
};

export default NoRightClickImage;
