import "../css/development.css";
import settings from "../assets/settings.png";
import NoRightClickImage from "./Disable";
import { Helmet, HelmetProvider } from "react-helmet-async";

function Tools() {
  return (
    <HelmetProvider>
      <Helmet title="Tools | Unvexed">
        <meta name="description" content="Tools page of Unvexed" />
      </Helmet>
      <center>
        <center>
          <NoRightClickImage src={settings} className="logo" alt="Vite logo" />
          <div>Under Development</div>
        </center>
      </center>
    </HelmetProvider>
  );
}

export default Tools;
