import { useEffect } from 'react';

const GoogleTagManager = () => {
  useEffect(() => {
    // Create a script element for the async gtag.js script
    const script1 = document.createElement('script');
    script1.async = true;
    script1.src = 'https://www.googletagmanager.com/gtag/js?id=G-W1BET3F3HT';
    document.head.appendChild(script1);

    // Create a script element for the gtag function
    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', 'G-W1BET3F3HT');
    `;
    document.head.appendChild(script2);

    // Clean up by removing the scripts when the component is unmounted
    return () => {
      document.head.removeChild(script1);
      document.head.removeChild(script2);
    };
  }, []);

  return null;
};

export default GoogleTagManager;
