import React, { useState, useEffect, useRef } from 'react';
import "../css/chat.css";
import TextToSpeech from '../components/tts'; // Adjust the import path as needed

export const Chat = () => {
  const [message, setMessage] = useState('');
  const [history, setHistory] = useState([]);
  const [isSpeaking, setIsSpeaking] = useState(false); // To track if the AI is speaking
  const [speechEnabled, setSpeechEnabled] = useState(true); // Toggle for speech
  const textToSpeech = TextToSpeech({ enabled: speechEnabled }); // Initialize the text-to-speech
  const typingTimeoutRef = useRef(null);
  const recognitionRef = useRef(null); // Reference for the speech recognition instance
  const [isListening, setIsListening] = useState(false); // To track listening state

  useEffect(() => {
    if (!window.SpeechRecognition && !window.webkitSpeechRecognition) return;

    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognition = new SpeechRecognition();
    recognition.lang = 'en-IN'; // Set language to Indian English
    recognition.interimResults = true;
    recognition.continuous = true; // Ensure continuous listening

    recognition.onresult = (event) => {
      let interimTranscript = '';
      for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcript = event.results[i][0].transcript;
        interimTranscript += transcript;

        if (event.results[i].isFinal) {
          console.log("Final transcript:", transcript);
          setMessage(transcript); // Set final transcript as the message
          handleSend(transcript); // Send the final transcript as the user message
        } else {
          console.log("Interim transcript:", interimTranscript);
        }
      }
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
      if (event.error === 'no-speech' || event.error === 'network') {
        // Restart recognition if it stops due to "no-speech" or network errors
        stopListening(); // Ensure to stop before restarting
        startListening();
      }
    };

    recognition.onend = () => {
      setIsListening(false); // Update state when recognition ends
      console.log("Speech recognition ended");
      startListening()
    };

    recognitionRef.current = recognition;
    // eslint-disable-next-line
  }, []);

  const startListening = () => {
    if (recognitionRef.current && !isListening) {
      try {
        recognitionRef.current.start();
        setIsListening(true);
      } catch (error) {
        console.error("Error starting speech recognition:", error);
      }
    }
  };

  const stopListening = () => {
    if (recognitionRef.current && isListening) {
      try {
        recognitionRef.current.stop();
        setIsListening(false);
      } catch (error) {
        console.error("Error stopping speech recognition:", error);
      }
    }
  };

  const handleSend = async (msg) => {
    const messageToSend = msg || message;

    if (messageToSend.trim() && !isSpeaking) {
      // Add user message to history
      setHistory((prevHistory) => [
        ...prevHistory,
        { text: messageToSend, sender: 'user' },
      ]);

      // Simulate AI response
      const aiResponse = "India, officially the Republic of India is a country in South Asia. It is the seventh-largest country by area; the most populous country with effect from June 2023; and from the time of its independence in 1947, the world's most populous democracy. Bounded by the Indian Ocean on the south, the Arabian Sea on the southwest, and the Bay of Bengal on the southeast, it shares land borders with Pakistan to the west; China, Nepal, and Bhutan to the north; and Bangladesh and Myanmar to the east. In the Indian Ocean, India is in the vicinity of Sri Lanka and the Maldives; its Andaman and Nicobar Islands share a maritime border with Thailand, Myanmar, and Indonesia."; // Replace with actual AI response logic

      // Add AI response to history with empty text initially
      setHistory((prevHistory) => [
        ...prevHistory,
        { text: '', sender: 'ai' },
      ]);

      // Start typing effect
      setIsSpeaking(true); // Set speaking to true before speaking
      let index = 0;
      const typingInterval = 50; // Adjust typing speed (ms)

      // Stop listening while speaking
      stopListening();

      // Start speech synthesis as soon as typing starts
      const speakPromise = speechEnabled ? textToSpeech.speak(aiResponse) : Promise.resolve();

      const type = () => {
        setHistory((prevHistory) => {
          const newHistory = [...prevHistory];
          newHistory[newHistory.length - 1].text = aiResponse.slice(0, index + 1);
          return newHistory;
        });

        index += 1;
        if (index < aiResponse.length) {
          typingTimeoutRef.current = setTimeout(type, typingInterval);
        } else {
          // End speech synthesis when typing effect completes
          speakPromise.finally(() => {
            setIsSpeaking(false); // Set speaking to false when done
            startListening(); // Resume listening
          });
        }
      };

      type();

      // Clear the input field
      setMessage('');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent the default Enter key behavior (e.g., form submission)
      handleSend();
    }
  };

  const isSendIconDisabled = !message.trim() || isSpeaking;

  useEffect(() => {
    return () => {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    };
  }, []);

  return (
    <>
      <div className="chat-container">
        <div className="message-container">
          {history.map((msg, index) => (
            <div
              key={index}
              className={`message ${msg.sender}-message`}
            >
              <div className={`avatar ${msg.sender}`}>
                {msg.sender === 'ai' ? '🤖' : '👤'}
              </div>
              <div className="message-text">{msg.text}</div>
            </div>
          ))}
        </div>

        <div className="message1">
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleKeyDown} // Handle Enter key press
            placeholder="Type your message..."
          />
          <div
            className={`send-icon ${isSendIconDisabled ? 'disabled' : ''}`}
            onClick={handleSend}
          />
        </div>

        <div className="speech-toggle">
          <label>
            <input
              type="checkbox"
              checked={speechEnabled}
              onChange={() => setSpeechEnabled(prev => !prev)}
            />
            Enable Speech
          </label>
        </div>

        <button onClick={startListening}>Start Listening</button>
        <button onClick={stopListening}>Stop Listening</button>
      </div>
    </>
  );
};
