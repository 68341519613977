import { useState, useEffect, useRef } from 'react';

const TextToSpeech = ({ enabled }) => {
  const [selectedVoice, setSelectedVoice] = useState(null);
  const synthRef = useRef(window.speechSynthesis);

  useEffect(() => {
    const getVoices = () => {
      const synth = synthRef.current;
      let voicesList = synth.getVoices();

      // Filter voices to include only Indian voices (e.g., en-IN)
      const indiaVoices = voicesList.filter((voice) =>
        voice.lang.includes('IN')
      );

      // Automatically select "Microsoft Prabhat Online (Natural) - English (India)" if available
      const prabhatVoice = indiaVoices.find(
        (voice) => voice.name === "Microsoft Prabhat Online (Natural) - English (India)"
      );

      if (prabhatVoice) {
        setSelectedVoice(prabhatVoice);
      } else if (indiaVoices.length > 0) {
        // Fallback to the first voice in the list if "Prabhat" is not found
        setSelectedVoice(indiaVoices[0]);
      } else {
        console.warn("No suitable voices found.");
      }
    };

    getVoices();
    if (typeof window !== 'undefined' && window.speechSynthesis) {
      window.speechSynthesis.onvoiceschanged = getVoices;
    }
  }, []);

  const speak = (text) => {
    console.log("Speaking text:", text);
    if (!text.trim() || !selectedVoice || !enabled) return Promise.resolve();

    const utterance = new SpeechSynthesisUtterance(text);
    utterance.voice = selectedVoice;

    return new Promise((resolve, reject) => {
      utterance.onend = () => resolve();
      utterance.onerror = (event) => reject(event.error);

      synthRef.current.speak(utterance);
    });
  };

  return { speak };
};

export default TextToSpeech;
