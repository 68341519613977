import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//toast
import "../css/los.css";

function Los({ ws }) {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const navigate = useNavigate();

  const handleSignupSubmit = async (e) => {
    e.preventDefault();

    try {
      const mac = localStorage.getItem("mac");
      ws.send({
        command: "signup",
        params: { email, password, userName, mobile, mac },
      });
      setFormSubmitted(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();

    try {
      const mac = localStorage.getItem("mac");
      ws.send({
        command: "signin",
        params: { loginEmail, loginPassword, mac },
      });
      setFormSubmitted(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const handleWebSocketMessage = (message) => {
      if (message.message === "signin" || message.message === "signup") {
        if (message.status === "error") {
          toast.error(message.error);
        } else if (message.user_id) {
          localStorage.setItem("user_id", message.user_id);
          navigate("/");
        }
      }
    };

    ws.on("message", handleWebSocketMessage);

    return () => {
      ws.off("message", handleWebSocketMessage);
    };
  }, [ws, navigate]);

  return (
    <HelmetProvider>
      <Helmet title="Signin/Signup | Unvexed">
        <meta name="description" content="Login page of Unvexed" />
      </Helmet>
      <center>
        <div>
          <br />
        </div>
        <div className="main">
          <input type="checkbox" id="chk" aria-hidden="true" />

          {/* Signup Section */}
          {!formSubmitted ? (
            <div className="signup">
              <form onSubmit={handleSignupSubmit}>
                <label htmlFor="chk" aria-hidden="true">
                  Sign up
                </label>
                <input
                  type="text"
                  name="txt"
                  placeholder="User name"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  required
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <input
                  type="tel"
                  name="phone"
                  placeholder="Mobile"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  required
                />
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <button type="submit">Sign up</button>
              </form>
            </div>
          ) : (
            <div className="testimonial-style">
              <p id="thankYouMessage">
                <img src="../p1.png" alt="" className="stars" />
                <br />
                Finished Successfully !
              </p>
            </div>
          )}

          {/* Login Section */}
          {!formSubmitted ? (
            <div className="login">
              <form onSubmit={handleLoginSubmit}>
                <label htmlFor="chk" aria-hidden="true">
                  Login
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={loginEmail}
                  onChange={(e) => setLoginEmail(e.target.value)}
                  required
                />
                <input
                  type="password"
                  name="pswd"
                  placeholder="Password"
                  value={loginPassword}
                  onChange={(e) => setLoginPassword(e.target.value)}
                  required
                />
                <button type="submit">Login</button>
              </form>
            </div>
          ) : (
            <div className="testimonial-style">
              <p id="loginSuccessMessage"></p>
            </div>
          )}
        </div>
        <div>
          <br />
        </div>
      </center>
      <ToastContainer />
    </HelmetProvider>
  );
}

export default Los;
